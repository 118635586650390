<template>
  <!-- Dialog Add Promotional Activity -->
  <ib-dialog
    id="dialog-story-mode-add-marketing-element"
    ref="dialog"
    :visible="dialogAddPromotionalActivityVisible"
    fullscreen
    @open="openDialog"
    @close="closeDialog"
    @closed="closedDialog"
  >
    <!-- Mobile title -->
    <template #title>
      <ib-dialog-container class="d-md-none">
        <h2>
          {{ $tc('addPromotionalActivity') }}
        </h2>
      </ib-dialog-container>
    </template>
    <!-- /Mobile title -->

    <!-- Content Wrapper -->
    <ib-dialog-container class="content-wrapper">
      <!-- Header -->
      <add-dialog-header>
        <template #left>
          <!-- Title -->
          <h2 class="m-0">
            {{ $tc('addPromotionalActivity') }}
          </h2>
          <!-- /Title -->
        </template>
      </add-dialog-header>
      <!-- /Header -->

      <!-- Info Guide -->
      <info-guide-add-dialog
        class="mb-3"
        :visible="guideVisible"
        :title="$t('pages.businessGuide.promotion.addDialog.guide.title')"
        :text="$t('pages.businessGuide.promotion.addDialog.guide.text')"
        @click="onToggleDialogGuide"
      />
      <!-- Info Guide -->

      <!-- Ai Buddy -->
      <ai-buddy type="promotion" class="ai-buddy-margin-bottom" />
      <!-- Ai Buddy -->

      <!-- Inner Wrapper -->
      <div class="inner-wrapper">
        <div class="grid-wrapper">
          <el-row :gutter="60">

            <!-- Left Side -->
            <el-col :md="12">
              <!-- Avatar setter -->
              <!--              <avatar-setter v-if="cropping" @click="cropImage" />-->
              <!-- /Avatar setter -->

              <!-- Form Wrapper -->
              <div class="promotion-form-wrapper">
                <!-- Avatar And Uploader -->
                <ib-avatar-uploader
                  ref="avatarUploader"
                  :image="form.image"
                  :default-image="defaultAvatarImage"
                  @cropping="cropping = true"
                  @change="form.croppedImage = $event"
                  @crop="cropImage"
                  @delete="deleteImage"
                />
                <!-- /Avatar And Uploader -->

                <!-- Form Inner -->
                <div class="product-form-inner">

                  <!-- Form -->
                  <el-form ref="form" :model="form" @submit.native.prevent>

                    <!-- Promotional Activity Name -->
                    <el-form-item
                      class="input-inside-label icon" :label="$t('pages.businessGuide.promotion.addDialog.fields.promotionalActivityName')"
                      :class="{ 'focused': focus.type }"
                    >

                      <!-- Symbol -->
                      <span class="symbol default" :class="form.type" />
                      <!-- /Symbol -->

                      <ib-select
                        v-model="form.niceName"
                        placeholder=" "
                        popper-class="promo-dropdown"
                        allow-create
                        :popper-append-to-body="false"
                        @change="changeType"
                      >
                        <ib-option
                          v-for="type in options.types"
                          :key="type.value"
                          :label="$t(type.label)"
                          :value="type.value"
                        >
                          <div class="detailed-option" :class="{ 'selected': type.value === form.type }">

                            <!-- Symbol & Label -->
                            <div class="flex">

                              <!-- Symbol -->
                              <img svg-inline :src="type.imgPath" class="icon-dropdown">
                              <!-- /Symbol -->

                              <!-- Label -->
                              <span class="label">{{ $t(type.label) }}</span>
                              <!-- /Label -->

                            </div>
                            <!-- Symbol & Label -->

                          </div>
                        </ib-option>
                      </ib-select>
                    </el-form-item>
                    <!-- /Promotional Activity Name -->

                    <!-- Name -->
                    <ib-input
                      v-model="form.name"
                      :label="$t('pages.businessGuide.promotion.addDialog.fields.name')"
                      :maxlength="50"
                    />
                    <!-- /Name -->

                    <!-- Description -->
                    <el-form-item>
                      <el-input
                        v-model="form.description"
                        type="textarea"
                        :rows="4"
                        class=""
                        :placeholder="$t('pages.businessGuide.promotion.addDialog.fields.shortDescription')"
                        resize="none"
                        maxlength="500"
                      />
                    </el-form-item>
                    <!-- /Description -->

                  </el-form>
                  <!-- /Form -->

                </div>
                <!-- /Form Inner -->

              </div>
              <!-- /Form Wrapper -->

              <!-- Dialog Bottom -->
              <div class="my-4 my-md-0">

                <!-- Divider -->
                <ib-divider block class="my-4" />
                <!-- /Divider -->

                <el-row :gutter="10" class="d-flex">
                  <el-col :span="12">
                    <!-- Close -->
                    <ib-button class="w-100" variant="white" size="lg" font-family="regular" @click="closeDialog">
                      {{ $t('close') }}
                    </ib-button>
                    <!-- /Close -->
                  </el-col>

                  <el-col :span="12">
                    <ib-button
                      class="w-100 text-uppercase"
                      size="lg"
                      :disabled="!isAddEnabled"
                      :loading="loading"
                      @click="savePromotionalActivity"
                    >
                      {{ form.id === 0 ? $t('add') : $t('save') }}
                    </ib-button>
                  </el-col>
                </el-row>
              </div>
              <!-- /Dialog Bottom -->
            </el-col>
            <!-- /Left Side -->

            <!-- Right Side -->
            <el-col :md="12">
              <add-dialog-entry-header>
                <template #left>
                  <h2>{{ $tc('promotionalActivity', 2) }}</h2>
                </template>
                <template #right>
                  <erase-icon-button
                    v-if="removeButtonVisible"
                    confirm
                    :loading="removeLoadingExamples"
                    @confirm="deleteExamples"
                  />
                </template>
              </add-dialog-entry-header>

              <!-- Promotional Activities Added -->
              <div ref="cardHolder" class="products-added">

                <ib-draggable
                  :list="getPromotions"
                  draggable=".promotion"
                  @change="onChangePromotionOrder"
                  @start="onStartPromotionOrder"
                >

                  <!-- Promotional Activities Added List -->
                  <div
                    v-for="promotionalActivity in getPromotions"
                    :key="promotionalActivity.id"
                    class="promotion cursor-move"
                    :class="promotionalActivity.type"
                    @click="onEditPromotionalActivity(promotionalActivity)"
                  >

                    <!-- Promotional Activity Image & Name -->
                    <div class="promotion-left">

                      <!-- Promotional Activity Image -->
                      <div>
                        <div
                          v-if="promotionalActivity.image"
                          class="img-wrapper img-wrapper-filled"
                          :style="cardImageBySize(promotionalActivity.image, 'small')"
                          :class="promotionalActivity.type"
                        />
                        <!-- /Promotional Activity Image -->

                        <!-- Default Image -->
                        <div v-if="!promotionalActivity.image" class="img-wrapper default" />
                        <!-- /Default Image -->
                      </div>

                      <!-- Promotional Activity Name -->
                      <p class="promotion-name">
                        {{ promotionalActivity.name }}
                      </p>
                      <!-- /Promotional Activity Name -->

                    </div>
                    <!-- /Promotional Activity Image & Name -->

                    <!-- Actions Dropdown -->
                    <actions-dropdown
                      ref="itemDropdownWrapper"
                      :entity="promotionalActivity"
                      :delete-disabled="form.id === promotionalActivity.id"
                      :edit-disabled="form.id === promotionalActivity.id"
                      @edit="onEditPromotionalActivity"
                      @copy="onCopyPromotionalActivity"
                      @delete="deleteStoryModePromotionalActivity($event.id)"
                    />
                    <!-- /Actions Dropdown -->

                  </div>
                </ib-draggable>
                <!-- /Promotional Activities Added List -->

              </div>
              <!-- /Promotional Activities Added -->

            </el-col>
            <!-- /Right Side -->

          </el-row>
        </div>
      </div>
      <!-- /Inner Wrapper -->
    </ib-dialog-container>
    <!-- /Content Wrapper -->

  </ib-dialog>
  <!-- /Dialog Add Promotional Activity -->

</template>

<script>
import ActionsDropdown from '@/views/Home/StoryMode/Components/ActionsDropdown'
import AddDialogEntryHeader from '@/components/_v2/Common/AddDialogEntryHeader'
import AddDialogHeader from '@/components/_v2/Common/AddDialogHeader'
// import AvatarSetter from '@/views/Home/StoryMode/Components/AvatarSetter'
import AiBuddy from '@/views/Home/StoryMode/Components/AiBuddy/AiBuddy.vue'
import EraseIconButton from '@/components/_v2/EraseIconButton'
import IbAvatarUploader from '@/components/_v2/IbAvatarUploader'
import IbOption from '@/components/_v2/Select/IbOption'
import InfoGuideAddDialog from '@/views/Home/StoryMode/Components/Guide/InfoGuideAddDialog.vue'
import MixinCloseMenu from '@/mixins/closeDropdownMenuOnScroll'
import MixinDeleteIdeaExamples from '@/mixins/deleteIdeaExamples'
import MixinDialog from '@/mixins/dialog'
import MixinGuide from '@/mixins/guide'
import MixinIdeaRoles from '@/mixins/permissions/ideaRoles'

import { mapActions, mapGetters } from 'vuex'
import { getImageBySize } from '@/helpers/imageSizeHelper'

export default {
  name: 'DialogAddPromotionalActivity',

  components: {
    AiBuddy,
    InfoGuideAddDialog,
    EraseIconButton,
    ActionsDropdown,
    AddDialogEntryHeader,
    AddDialogHeader,
    // AvatarSetter,
    IbAvatarUploader,
    IbOption
  },

  mixins: [
    MixinCloseMenu,
    MixinDialog,
    MixinGuide,
    MixinIdeaRoles,
    MixinDeleteIdeaExamples
  ],

  props: {
    dialogAddPromotionalActivityVisible: {
      type: Boolean,
      default: false
    },
    promotionalActivityAction: {
      type: Object,
      default: null
    },
    options: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      loading: false,
      removeLoadingExamples: false,
      cropping: false,
      guideVisible: false,
      form: {
        id: 0,
        image: null,
        uploadedImage: null,
        croppedImage: null,
        ideaId: this.$store.state.idea.id,
        name: '',
        niceName: '',
        type: '',
        description: '',
        differentiators: ''
      },
      focus: {
        type: false
      }
    }
  },

  computed: {
    ...mapGetters('idea', [
      'getPromotions',
      'getHasItemsFromTemplate'
    ]),

    isAddEnabled () {
      return this.form.type && this.form.name
    },

    isDialogOpen () {
      return this.dialogAddPromotionalActivityVisible
    },

    defaultAvatarImage () {
      if (this.form.type && !this.form.image) {
        const type = this.options.types.find(type => this.form.type === type.value)
        if (type) {
          return type.avatarImg
        }
      }

      return require('@/assets/img/icons/story-mode/megaphone.svg')
    },

    removeButtonVisible () {
      return this.canEdit && this.getHasItemsFromTemplate('getPromotions')
    }
  },

  methods: {
    ...mapActions('idea', [
      'deleteStoryModePromotionalActivity',
      'updateOrderNumber',
      'deleteIdeaExamples'
    ]),

    onStartPromotionOrder () {
      this.$refs.itemDropdownWrapper.forEach(dropdown => {
        dropdown.$refs.itemDropdown.visible = false
      })
    },

    onChangePromotionOrder (data) {
      this.updateOrderNumber({
        type: 'promotion',
        data,
        oldOrderData: this.$store.state.idea.storyMode.concept.promotion.promotionalActivities,
        mutation: 'setPromotionalActivity'
      })
    },

    closeDialog () {
      this.$emit('close-dialog-add-promotional-activity')
    },

    closedDialog () {
      this.resetForm()
      this.guideVisible = false
    },

    openDialog () {
      this.openGuideDialog('addPromotionGuide')
      this.resetForm()
      if (this.promotionalActivityAction) {
        this.form.id = this.promotionalActivityAction.id
        this.form.ideaId = this.$store.state.idea.id
        this.form.image = this.promotionalActivityAction.image
        this.form.name = this.promotionalActivityAction.name
        this.form.type = this.promotionalActivityAction.type
        this.form.description = this.promotionalActivityAction.description
        for (let i = 0; i < this.options.types.length; i++) {
          if (this.form.type === this.options.types[i].value) {
            this.form.niceName = this.$t(this.options.types[i].label)
          }
        }
      }
      this.checkInputLength('type')
    },

    onToggleDialogGuide () {
      this.guideVisible = !this.guideVisible
    },

    toggleFocus (input) {
      this.focus[input] = !this.focus[input]

      if (this.form[input]) {
        this.focus[input] = true
      }
    },

    resetForm () {
      this.form = {
        id: 0,
        ideaId: this.$store.state.idea.id,
        image: null,
        uploadedImage: null,
        croppedImage: null,
        name: '',
        type: '',
        niceName: '',
        description: ''
      }
      this.focus = {
        name: false,
        type: false
      }
      this.cropping = false
      if (this.$refs.avatarUploader) {
        this.$refs.avatarUploader.reset()
      }
    },

    resetGuide () {
      if (this.$store.getters['idea/getPromotions'].length === 0) {
        this.guideVisible = false
      }
    },

    savePromotionalActivity () {
      this.loading = true
      if (this.cropping) {
        this.cropImage()
      }
      if (this.form.id === 0) {
        this.addPromotionalActivity()
      } else {
        this.updatePromotionalActivity()
      }
    },

    addPromotionalActivity () {
      delete this.form.uploadedImage
      this.$http.post('story-mode/concept/promotion/promotional-activities', this.form)
        .then((response) => {
          if (response.status === 201) {
            this.resetGuide()
            this.$store.commit('idea/addPromotionalActivity', response.data.payload.promotionalActivity)
            this.resetForm()
          }
        }).finally(() => {
          this.loading = false
        })
    },

    updatePromotionalActivity () {
      this.$http.put(`story-mode/concept/promotion/promotional-activities/${this.form.id}`, this.form)
        .then((response) => {
          if (response.status === 200) {
            this.$store.commit('idea/updatePromotionalActivity', response.data.payload.promotionalActivity)
            this.resetForm()
          }
        }).finally(() => {
          this.loading = false
        })
    },

    onEditPromotionalActivity (promotionalActivity) {
      this.resetForm()
      this.form = Object.assign({}, promotionalActivity)
      this.form.ideaId = this.$store.state.idea.id
      this.$set(this.form, 'uploadedImage', null)
      this.$set(this.form, 'croppedImage', null)
      for (let i = 0; i < this.options.types.length; i++) {
        if (this.form.type === this.options.types[i].value) {
          this.form.niceName = this.$t(this.options.types[i].label)
        }
      }
      this.checkInputLength('type')
      this.scrollToTop()
    },

    onCopyPromotionalActivity (promotionalActivity) {
      this.resetForm()
      this.form = Object.assign({}, promotionalActivity)
      this.form.id = 0
      this.form.ideaId = this.$store.state.idea.id
      this.$set(this.form, 'uploadedImage', null)
      this.$set(this.form, 'croppedImage', null)
      for (let i = 0; i < this.options.types.length; i++) {
        if (this.form.type === this.options.types[i].value) {
          this.form.niceName = this.$t(this.options.types[i].label)
        }
      }
      this.checkInputLength('type')
      this.scrollToTop()
    },

    checkInputLength (input) {
      this.focus[input] = !!this.form[input]
    },

    deleteImage () {
      this.form.image = null
      this.form.uploadedImage = null
      this.form.croppedImage = null
    },

    cropImage () {
      this.cropping = false
      const image = this.$refs.avatarUploader.cropImage()
      if (image) {
        this.form.croppedImage = image.croppedImage
        this.form.uploadedImage = image.uploadedImage

        return
      }
      this.deleteImage()
    },

    cardImage (img) {
      if (img) {
        return 'background-image: url(' + img + ')'
      } else {
        return ''
      }
    },

    cardImageBySize (image, size) {
      return image ? 'background-image: url(' + getImageBySize(image.sizes, size) + ')' : ''
    },

    changeType (value) {
      for (let i = 0; i < this.options.types.length; i++) {
        if (value === this.options.types[i].value) {
          this.form.type = this.options.types[i].value
          this.form.name = this.$t(this.options.types[i].label)
          this.toggleFocus('type')
          this.toggleFocus('name')
        } else {
          this.form.type = value
          this.toggleFocus('type')
          this.toggleFocus('name')
        }
      }
    },

    async deleteExamples () {
      try {
        this.removeLoadingExamples = true
        const response = await this.deleteIdeaExamples('promotions')
        const { type, ids } = response
        this.deleteFromState({ type, ids })
        this.resetForm()
        this.removeLoadingExamples = false
      } catch (error) {
        this.removeLoadingExamples = false
      }
    }
  }
}
</script>
